<style scoped>
.c-progress-button {
  opacity: unset !important;
  cursor: not-allowed;
}
@media (max-width: 1024px) {
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  props: ['plan', 'planInfo'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    HalfCircleSpinner,
    PaymentMethods: () => import('@/components/lazy/billing/PaymentMethods'),
  },
  validations: {

  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
    ])
  },
  methods: {
    async canContinue() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-methods?valid_only=true`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.payment_methods.length > 0) return true;
          return false;
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        return false;
      }
    },
    async doContinue() {
      this.processing = true;
      let status = await this.canContinue();
      if(!status) {
        this.$swal({
          icon: 'info',
          text: this.$t('billing.checkout.payment_method.prompt')
        });
        this.processing = false;
        return;
      }
      this.$emit('frameCompleted');
    }
  },
  created() {

  },
  async mounted() {
    this.$emit('frameReady');
  },
  destroyed() {

  },
  data() {
    return {
      ready: true,
      error: false,
      processing: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <PaymentMethods :hideDebug="true" />
      <div class="row mt-4">
        <div class="col">
          <div class="text-center mb-3">
            <button class="btn btn-lg btn-primary" :class="{'disabled': processing}" :disabled="processing" v-on:click="doContinue()">
              <h5 class="text-uppercase mb-0">
                <half-circle-spinner
                    v-if="processing"
                    :animation-duration="900"
                    :size="18"
                    class="align-middle d-inline-block"
                />
                {{ $t('billing.checkout.continue') }}
              </h5>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
